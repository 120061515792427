<template>
  <div class="addaddr">
    <title-top>添加地址</title-top>
    <div class="mb8"></div>
    <div class="row align-center bg-white h80 mb8 font24">
      <span class="f-333333 fontweight400 ml25 space">收货人</span>
      <input
        class="font24 flex1 f-999999 mr25"
        type="text"
        placeholder="请输入收货人姓名"
        v-model="contact_name"
        ref="contact_name"
      />
    </div>
    <div class="row align-center bg-white h80 mb8 font24">
      <span class="f-333333 fontweight400 ml25 space">手机号码</span>
      <input
        class="font24 flex1 f-999999 mr25"
        type="number"
        placeholder="请输入手机号码"
        v-model="mobile"
      />
    </div>
    <div class="row align-center between bg-white h80 mb8" @click="onAddr">
      <div class="row align-center">
        <span class="font24 f-333333 fontweight400 ml25 space">所在地区</span>
        <span class="font24 f-999999 mr25">{{district_name}}</span>
      </div>

      <img class="imgsize32 mr25" src="~images/nextbtn.png" alt />
    </div>
    <div class="row align-center bg-white h80 mb8 font24">
      <span class="f-333333 fontweight400 ml25 space">详细地址</span>
      <input
        class="font24 flex1 f-999999 mr25"
        type="text"
        placeholder="地址填写需包括楼栋楼层或房间号"
        v-model="street"
      />
    </div>
    <div class="row align-center betwe0en bg-white h80 mb8 font24 f-333333">
      <span class="fontweight400 ml25 space">设置默认地址</span>
      <div class="row flex-center mr25">
        <div @click="onCur(0)" class="row align-center">
          <img class="imgsize32 mr16" src="~images/selecticon.png" v-if="current==0" alt />
          <img class="imgsize32 mr16" src="~images/not-sel.png" v-else alt />
          <span class="mr16">是</span>
        </div>
        <div @click="onCur(1)" class="row align-center">
          <img class="imgsize32 mr16" src="~images/selecticon.png" v-if="current==1" alt />
          <img class="imgsize32 mr16" src="~images/not-sel.png" v-else alt />
          <span>否</span>
        </div>
      </div>
    </div>
    <div class="row flex-center" @click="onSure">
      <div class="btn-red mt100" style="background-color:#41ABA7;width:8rem;">确认</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import { addAddress, set_as_default } from "network/igou2";
export default {
  name: "AddAddr",
  data() {
    return {
      contact_name: "", //联系人
      mobile: "", //手机
      district_id: "", //所在区/县ID
      street: "", //详细地址
      zipcode: "", //邮编
      default: true, //设置默认地址,
      district_name: "",
      current: 0
    };
  },
  mounted() {
    if (localStorage.getItem("district")) {
      let district = JSON.parse(localStorage.getItem("district"));
      this.district_id = district.district_id;
      this.district_name = district.district_name;
    }
    this.getInput();
  },
  methods: {
    getInput(){
      this.contact_name = localStorage.getItem("contact_name") || '';
      this.mobile = localStorage.getItem("mobile") || '';
      this.street = localStorage.getItem("street") || '';
      this.zipcode = localStorage.getItem("zipcode") || '';
      this.default = localStorage.getItem("default") || true;
      this.current = localStorage.getItem("current") || 0;
    },
    setInput(){
      localStorage.setItem("contact_name",this.contact_name);
      localStorage.setItem("mobile",this.mobile);
      localStorage.setItem("street",this.street);
      localStorage.setItem("zipcode",this.zipcode);
      localStorage.setItem("default",this.default);
      localStorage.setItem("current",this.current);
    },
    clearInput(){
      localStorage.removeItem("contact_name");
      localStorage.removeItem("mobile");
      localStorage.removeItem("street");
      localStorage.removeItem("zipcode");
      localStorage.removeItem("default");
      localStorage.removeItem("current");
    },
    _set_as_default(id) {
      set_as_default(id).then(res => {
        this.$router.back();
      });
    },
    onCur(index) {
      this.current = index;
      this.default = index == 0 ? true : false;
    },
    onSure() {
      this._addAddress();
    },
    onAddr() {
      this.setInput();
      this.$router.push("/zyselectdomain");
    },
    _addAddress() {
      /*let contact_name = this.contact_name;
      let mobile = this.mobile;
      let district_id = this.district_id;
      let street = this.street;
      let zipcode = this.zipcode;
      let default = this.default;*/
      let data = {
        contact_name: this.contact_name, //联系人
        mobile: this.mobile, //手机
        district_id: this.district_id, //所在区/县ID
        street: this.street, //详细地址
        zipcode: this.zipcode, //邮编
        default: this.default //设置默认地址
      };
      addAddress(data).then(res => {
        console.log(res);
        localStorage.removeItem("district");
        this.clearInput();
        if (this.current == 0) {
          this._set_as_default(res.data.id);
        } else {
          this.$router.back();
        }
      });
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.space {
  width: 2.666667rem;
}
</style>
